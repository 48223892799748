.title {
    font-size: var(--title-size, 18px);
    font-weight: var(--title-weight, 500);
    color: var(--title-color, var(--gray-70));
}

@media (min-width: 640px) {
    .container {
        max-width: var(--modal-max-width, 600px);
    }
}
